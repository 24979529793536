@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "roboto";
  src: url("../public/font/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "over";
  src: url("../public/font/Overpass-Regular.ttf");
}
@font-face {
  font-family: "over-bold";
  src: url("../public/font/Overpass-Bold.ttf");
}

@font-face {
  font-family: "namsan";
  src: url("../public/font/SeoulNamsanL.ttf");
}

@font-face {
  font-family: "hangang";
  src: url("../public/font/SeoulHangangM.ttf");
}
@font-face {
  font-family: "binggrae";
  src: url("../public/font/Binggrae.ttf");
}
@font-face {
  font-family: "iropke";
  src: url("../public/font/IropkeBatangM.ttf");
}

body {
  font-family: "iropke";
}
